import React from 'react';
import { useFormContext } from '../../../hoc/form';
import CheckBox from './CheckBox';

export default function GroupBox({ group, ...props }) {
	const context = useFormContext();
	if(!group) {
		return null;
	}
	delete props.value;
	if(context) {
		const { values } = context;
		props.checked = !!(values && group.every(name => values[name]));
	} else {
		props.checked = false;
	}
	return <CheckBox {...props} type="checkbox" onChange={e => {
		if(context?.changeValue) {
			const event = { target: { value: e.target.checked } };
			group.forEach(name => {
				context.changeValue(name)(event);
			});
		}
	}} />;
}
