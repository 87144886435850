import SweetAlert from 'sweetalert2';
import withReactContext from 'sweetalert2-react-content';
import 'sweetalert2/dist/sweetalert2.css';

const defaultOptions = {
	showCancelButton: true,
	icon: 'warning'
};

const Prompt = withReactContext(SweetAlert);

export function fire(prompt) {
	return Prompt.fire(Object.assign({}, defaultOptions, prompt));
}

export default Prompt;
