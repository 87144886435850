import React, { useEffect, useState } from 'react';
import { Form, ButtonGroup } from 'reactstrap';
import { useApi } from './ApiState';
import { fire } from '../../../prompt';
import withInputs from '../../../hoc/form';
import { buildPage } from '../Page';

function Submit({ submit, onSubmit, submitting, canSubmit, index }) {
	const buttons = submit.values || [submit];
	return <>
		{buttons.map((button, i) => <input
			key={i}
			type={index === i ? 'submit' : 'button'}
			className={button.className || 'btn btn-success'}
			value={button.value}
			onClick={onSubmit(i)}
			disabled={submitting || !canSubmit(button.require)} />)}
	</>;
}

function PortalForm({ renderInputs, submit, children, buttons, autoSubmit, canSubmit, values, forceFormData, prompt, context, ...props }) {
	const { submitForm, callRawMethod } = useApi();
	const [submitting, setSubmitting] = useState(false);
	const [first, setFirst] = useState(true);
	const doSubmit = i => {
		if(!submit || !submit.post) {
			return;
		}
		setSubmitting(true);
		let data = values;
		const submitButtons = submit.values || [submit];
		if(submitButtons[i]?.data) {
			data = { ...data, ...submitButtons[i].data };
		}
		if(submit.raw) {
			callRawMethod('post', submit.post, context, data);
			return setSubmitting(false);
		}
		submitForm(context, submit.post, data, forceFormData).then(() => {
			setSubmitting(false);
		});
	};
	const onSubmit = (i, check) => {
		return e => {
			e.preventDefault();
			const submitButtons = submit.values || [submit];
			if(check) {
				if(submitting) {
					return;
				}
				if(!canSubmit(submitButtons[i]?.require)) {
					return;
				}
			}
			const p = submitButtons[i]?.prompt || prompt;
			if(p) {
				return fire(p).then(res => {
					if(res.value) {
						doSubmit(i);
					}
				});
			}
			doSubmit(i);
		};
	};
	const index = submit.defaultSubmit || 0;
	useEffect(() => {
		if(first) {
			return setFirst(false);
		}
		setSubmitting(false);
		if(autoSubmit) {
			doSubmit(index);
		}
	}, [values]);
	return <Form {...props} onSubmit={onSubmit(index, true)}>
		{renderInputs()}
		{children}
		{!autoSubmit && <ButtonGroup>
			{buttons && buttons.map(buildPage)}
			{submit && <Submit submit={submit} onSubmit={onSubmit} submitting={submitting} canSubmit={canSubmit} index={index} />}
		</ButtonGroup>}
	</Form>;
}

export default withInputs(PortalForm);
