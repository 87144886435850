import React from 'react';
import PortalNav from './PortalNav';

function Category({ headers }) {
	if(!headers.length) {
		return null;
	}
	return <ul>
		{headers.map((header, i) => <PortalNav key={i} {...header} />)}
	</ul>;
}

export default function Menu({ headers }) {
	const byCategory = {};
	const noCategory = [];
	headers.forEach(header => {
		if(header.category) {
			if(!byCategory[header.category]) {
				byCategory[header.category] = [];
			}
			byCategory[header.category].push(header);
		} else {
			noCategory.push(header);
		}
	});
	return <div id="portal-menu" className="d-print-none">
		{Object.keys(byCategory).map(category => {
			return <Category key={category} headers={byCategory[category]} />;
		})}
		<Category headers={noCategory} />
	</div>;
}
