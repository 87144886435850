import React, { useContext } from 'react';
import { MultiValue } from './FormValueGroup';

export default function FormValueGroupDeleteButton(props) {
	const context = useContext(MultiValue);
	if(!context) {
		return null;
	}
	return <button {...props} onClick={context} />;
}
