import React from 'react';

export default function SortInput({ field, onChange, value, initialDirection = 1, ...pass }) {
	if(!field) {
		return null;
	}
	value = +(value?.field === field && value.direction);
	let next = value + initialDirection;
	if(next > 1) {
		next = -1;
	} else if(next < -1) {
		next = 1;
	}
	let arrow = '↕';
	if(value === -1) {
		arrow = '↓';
	} else if(value === 1) {
		arrow = '↑';
	}
	delete pass.type;
	if(value) {
		pass.className = pass.className ? `${pass.className} active` : 'active';
	}
	pass.role = 'button';
	return <label {...pass}>
		{arrow}
		<input type="checkbox" hidden onChange={() => {
			onChange({ target: { value: { field, direction: next } } });
		}} />
	</label>;
}
