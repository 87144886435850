import React, { useEffect, useMemo, useState, useRef } from 'react';
import queryString from 'query-string';
import ErrorPage from './ErrorPage';
import ErrorBoundary, { useError } from '../ErrorBoundary';
import ApiState from './elements/ApiState';
import { useParams, useLocation } from 'react-router-dom';
import { usePageState } from '../PageState';
import { getPage } from '../../api';
import elements from './elements';

export function buildPage(page, key) {
	if(typeof page !== 'object' || !page) {
		return page;
	}
	if(!elements[page.element]) {
		return null;
	}
	let properties = page.properties || {};
	const children = page.children || [];
	if(key !== undefined && !('key' in properties)) {
		if(properties === page.properties) {
			properties = { ...page.properties };
		}
		properties.key = key;
	}
	if(!children.length) {
		return React.createElement(elements[page.element], properties);
	}
	return React.createElement(elements[page.element], properties, children.map(buildPage));
}

function ApiTree({ page, setErrorRef, render }) {
	const setError = useError();
	setErrorRef.current = setError;
	return render && buildPage(page);
}

function usePath() {
	const { api, endpoint, '*': remaining } = useParams();
	const url = useMemo(() => {
		const segments = [endpoint];
		if(remaining) {
			segments.push(...remaining.split('/'));
		}
		return segments.map(encodeURIComponent).join('/');
	}, [endpoint, remaining]);
	return { api, endpoint: url };
}

export default function Page() {
	const setErrorRef = useRef(() => undefined);
	const { page, actions, api: pageApi } = usePageState();
	const { setPage } = actions;
	const { api, endpoint } = usePath();
	useEffect(() => {
		const guard = setPage(null);
		setErrorRef.current(null);
		getPage(api, endpoint).then(p => setPage(p, api, guard)).catch(setErrorRef.current);
	}, [api, endpoint]);
	return <ErrorBoundary Elem={ErrorPage}>
		<ApiState api={api}>
			<ApiTree page={page} setErrorRef={setErrorRef} render={api === pageApi} />
		</ApiState>
	</ErrorBoundary>;
}

const NO_PARAMS = { search: {} };

export function useLocationParams() {
	const { search, hash } = useLocation();
	const [locationParams, setLocationParams] = useState({ params: NO_PARAMS });
	useEffect(() => {
		setLocationParams(prev => {
			let out = prev;
			if(prev.search !== search) {
				out = { ...prev, search, params: { ...prev.params, search: queryString.parse(search) } };
			}
			if(prev.hash !== hash) {
				if(out === prev) {
					out = { ...prev, hash, params: { ...prev.params, hash } };
				} else {
					out.hash = hash;
					out.params.hash = hash;
				}
			}
			return out;
		});
	}, [search, hash]);
	return locationParams.params;
}
