import { useEffect } from 'react';
import { useError, withErrorBoundary } from '../ErrorBoundary';
import { buildPage } from './Page';
import ErrorPage from './ErrorPage';
import { getHomePage } from '../../api';
import { usePageState } from '../PageState';
import { useUser } from '../UserState';

function WelcomePage() {
	const user = useUser();
	const { page, actions, api } = usePageState();
	const setError = useError();
	useEffect(() => {
		const setPage = actions.getSetFuturePage();
		getHomePage().then(p => setPage(p, null)).catch(setError);
	}, [user]);
	return !api && buildPage(page);
}

export default withErrorBoundary(WelcomePage, ErrorPage);
