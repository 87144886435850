import React, { memo, useEffect, useState } from 'react';
import { FormGroup, Input, Table } from 'reactstrap';
import Currency from './Currency';
import AutoComplete from './AutoComplete';

const PERCENT = 100;

function calcPercentage(price, discountPrice) {
	const mult = (price - discountPrice) / price;
	return mult * PERCENT;
}

function ItemDiscountLine({ item, onChange }) {
	const [value, setValue] = useState(() => ({ discountPrice: item.discountPrice ?? item.price, percentage: calcPercentage(item.price, item.discountPrice ?? item.price) }));
	useEffect(() => {
		if(!isNaN(value.discountPrice)) {
			const discountPrice = +value.discountPrice;
			if(discountPrice !== item.discountPrice) {
				onChange(item.code, discountPrice);
			}
		}
	}, [value.discountPrice]);
	return <tr>
		<td>{item.code}</td>
		<td><Currency currency="EUR" number={item.price} /></td>
		<td><Input type="number" step="0.01" min="0" max={item.price} value={value.discountPrice} onChange={e => {
			const discountPrice = e.target.value;
			setValue(prev => {
				let { percentage } = prev;
				if(!isNaN(discountPrice)) {
					percentage = calcPercentage(item.price, discountPrice);
				}
				return { discountPrice, percentage };
			});
		}} /></td>
		<td><Input type="number" step="0.01" min="0" max="100" value={value.percentage} onChange={e => {
			const percentage = e.target.value;
			setValue(prev => {
				let { discountPrice } = prev;
				if(!isNaN(percentage)) {
					const mult = 1 - percentage / PERCENT;
					discountPrice = item.price * mult;
				}
				return { discountPrice, percentage };
			});
		}} /></td>
	</tr>;
}

const Line = memo(ItemDiscountLine);

export default function VariableDiscount({ onChange, value, autoComplete }) {
	const [input, setInput] = useState('');
	const update = (code, discountPrice) => {
		onChange({ target: { value: value.map(item => {
			if(item.code === code) {
				return { ...item, discountPrice }; 
			}
			return item;
		}) } });
	};
	return <>
		{autoComplete && <FormGroup>
			<AutoComplete autoComplete={autoComplete} value={input} onChange={e => {
				const item = e.target.value;
				if(typeof item === 'object') {
					if(!value.find(v => v.code === item.code)) {
						onChange({ target: {
							value: [{ ...item, discountPrice: item.price, percentage: 0 }].concat(value)
						} });
						setInput('');
					}
				} else {
					setInput(item);
				}
			}} placeholder="SKU" />
		</FormGroup>}
		<Table>
			<thead>
				<tr>
					<th>SKU</th>
					<th>Base price</th>
					<th>Discount price</th>
					<th>Discount percentage</th>
				</tr>
			</thead>
			<tbody>
				{value?.map(item => {
					return <Line key={item.code} item={item} onChange={update} />;
				})}
			</tbody>
		</Table>
	</>;
}
