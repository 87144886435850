import { Fragment } from 'react';
import {
	Button, ButtonGroup, Col, FormGroup, InputGroup, InputGroupAddon, ListGroup,
	ListGroupItem, Nav, NavItem, Row, Table
} from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import QRCode from 'qrcode.react';
import ActionButton from './elements/ActionButton';
import Alert from './elements/Alert';
import ApiState from './elements/ApiState';
import Currency from './elements/Currency';
import Form from './elements/Form';
import FormValueGroup from './elements/FormValueGroup';
import FormValueGroupDeleteButton from './elements/FormValueGroupDeleteButton';
import Img from './elements/Img';
import Input from './elements/Input';
import Link from './elements/Link';
import Modal from './elements/Modal';
import Paginator from './elements/Paginator';
import StandardPage from './elements/StandardPage';
import Time from './elements/Time';
import a from './elements/a';

const elements = {
	a,
	ActionButton,
	Alert,
	ApiState,
	Button,
	ButtonGroup,
	Col,
	Currency,
	Form,
	FormGroup,
	FormValueGroup,
	FormValueGroupDeleteButton,
	Fragment,
	Icon,
	Img,
	Input,
	InputGroup,
	InputGroupAddon,
	Link,
	ListGroup,
	ListGroupItem,
	Modal,
	Nav,
	NavItem,
	Paginator,
	QRCode,
	Row,
	StandardPage,
	Table,
	Time
};

[
	'main', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8', 'p', 'div', 'span',
	'br', 'hr', 'li', 'ul', 'img', 'tr', 'td', 'th', 'thead', 'tbody', 'tfoot',
	'table', 'section', 'input', 'label', 'textarea', 'code', 'strong', 'em',
	'pre', 'button', 'small', 'wbr', 's'
].forEach(element => {
	elements[element] = element;
});

export default elements;
