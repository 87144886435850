import React, { useState } from 'react';

function onDragOver(e) {
	e.stopPropagation();
	e.preventDefault();
}

export default function FileDrop({ onChange, ...props }) {
	const [dragActive, setDragActive] = useState(false);
	if(!onChange) {
		return null;
	}
	delete props.value;
	return <div onDragOver={onDragOver} onDragEnter={() => setDragActive(true)} onDrop={e => {
		e.preventDefault();
		e.stopPropagation();
		onChange({ target: { value: e.dataTransfer.files } });
		setDragActive(false);
	}} className="d-flex flex-column align-items-center justify-content-center m-2 file-drop text-center">
		{dragActive ? <div className="file-release d-flex flex-column justify-content-center h4" onDragLeave={() => setDragActive(false)}>
			Release to select file
		</div> : <>
			<h5>Drag and drop a file here<br/> or </h5>
			<label>
				<span className="btn btn-primary">Select a file</span>
				<input {...props} hidden type="file" onChange={e => onChange({ target: { value: e.target.files } })} />
			</label>
		</>}
	</div>;
}
