import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
const HOURS = 24;
const MINUTES = 60;
const SEGMENTS = 2;
const SEGMENT_LENGTH = 2;
const DEFAULT = '00:00';

function pad(n) {
	const str = `${n}`;
	if(str.length === 1) {
		return `0${str}`;
	}
	return str;
}

function getValue(hours, minutes) {
	if(hours < 0 || minutes < 0) {
		return '';
	}
	return `${pad(hours)}:${pad(minutes)}`;
}

function parseValue(value, defaultValue = 0) {
	const split = value.split(':');
	let hours = defaultValue;
	let minutes = defaultValue;
	if(split.length === 1) {
		if(value.length > SEGMENT_LENGTH && value.length <= SEGMENT_LENGTH * SEGMENTS) {
			split[0] = value.slice(0, value.length - SEGMENT_LENGTH);
			split[1] = value.slice(value.length - SEGMENT_LENGTH);
		} else if(value.length <= SEGMENT_LENGTH && value.length) {
			if(value >= 0 && value < HOURS) {
				split[0] = value;
				split[1] = '00';
			} else if(value >= 0 && value < MINUTES) {
				split[0] = '00';
				split[1] = value;
			}
		}
	}
	if(split.length === SEGMENTS) {
		if(split[0] >= 0 && split[0] < HOURS) {
			hours = +split[0];
		}
		if(split[1] >= 0 && split[1] < MINUTES) {
			minutes = +split[1];
		}
	}
	return { hours, minutes, value: getValue(hours, minutes) };
}

export default function TimeInput({ value, onChange, onBlur, allowEmpty = false, ...props }) {
	const fallback = allowEmpty ? '' : DEFAULT;
	let invalid;
	if(allowEmpty) {
		invalid = -1;
	}
	const [state, setState] = useState(() => parseValue(value || fallback, invalid));
	useEffect(() => {
		setState(parseValue(value || fallback, invalid));
	}, [value, fallback, invalid]);
	return <Input {...props} type="text" value={state.value} onChange={e => {
		setState(prev => {
			const replacement = { ...prev, value: e.target.value };
			const parsed = parseValue(replacement.value, -1);
			if(parsed.hours >= 0 && parsed.minutes >= 0 || allowEmpty) {
				replacement.hours = parsed.hours;
				replacement.minutes = parsed.minutes;
			}
			return replacement;
		});
	}} onBlur={e => {
		const { hours, minutes } = state;
		const v = getValue(hours, minutes);
		if(state.value !== v) {
			setState({ ...state, value: v });
		}
		if(onBlur) {
			onBlur(e);
		}
		if(onChange) {
			onChange({ target: { value: v } });
		}
	}} />;
}
