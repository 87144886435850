import React, { useState, useEffect, useRef } from 'react';
import { Pagination, PaginationItem, PaginationLink, Form, FormGroup } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { buildPage, useLocationParams } from '../Page';
import { useApi } from './ApiState';
import { useReload, useModifiable } from '../../PageState';
import withInputs from '../../../hoc/form';
const PAGINATOR_LINKS = 2;

function QueryForm({ className, require, submit, onSubmit, children, getting, canSubmit }) {
	return <Form onSubmit={onSubmit} className={className}>
		{children}
		<FormGroup>
			<input
				type="submit"
				className={submit.className || 'btn btn-success'}
				value={submit.value}
				disabled={!!getting || !canSubmit(require)} />
		</FormGroup>
	</Form>;
}

function PaginatorComponent({ render, pass, getting, children, form, values, canSubmit, renderInputs, fetchWithValues, getLink }) {
	const { total, items, perPage, page } = render;
	const totalPages = Math.max(Math.ceil(total / perPage), 1);
	let min = Math.max(page - PAGINATOR_LINKS, 1);
	let max = Math.min(page + PAGINATOR_LINKS, totalPages);
	min = Math.max(1, min + Math.min(0, max - page - PAGINATOR_LINKS));
	max = Math.min(totalPages, max + Math.max(0, min - page + PAGINATOR_LINKS));
	const links = [];
	for (let i = min; i <= max; i++) {
		links.push(<PaginationItem active={page === i} key={i}>
			<PaginationLink tag={Link} to={getLink(i)}>{i}</PaginationLink>
		</PaginationItem>);
	}
	return <div {...pass}>
		{form && <QueryForm {...form} onSubmit={e => {
			e.preventDefault();
			fetchWithValues(values);
		}} getting={getting} canSubmit={canSubmit}>
			{renderInputs()}
		</QueryForm>}
		{children}
		{items.map(buildPage)}
		<Pagination className="mt-5" listClassName="justify-content-center">
			{page > PAGINATOR_LINKS + 1 ?
				<PaginationItem disabled={page === 1}>
					<PaginationLink tag={Link} first to={getLink(1)} />
				</PaginationItem>
				: null}
			{page > PAGINATOR_LINKS + 1 ?
				<PaginationItem disabled={page <= 1}>
					<PaginationLink tag={Link} previous to={getLink(page - 1)} />
				</PaginationItem>
				: null}
			{page > PAGINATOR_LINKS + 1 ?
				<PaginationItem disabled={true}>
					<PaginationLink>...</PaginationLink>
				</PaginationItem>
				: null}
			{links}
			{page < totalPages - PAGINATOR_LINKS ?
				<PaginationItem disabled={true}>
					<PaginationLink>...</PaginationLink>
				</PaginationItem>
				: null}
			{page < totalPages - PAGINATOR_LINKS ?
				<PaginationItem disabled={page >= totalPages}>
					<PaginationLink tag={Link} next to={getLink(page + 1)} />
				</PaginationItem>
				: null}
			{page < totalPages - PAGINATOR_LINKS ?
				<PaginationItem disabled={page === totalPages}>
					<PaginationLink tag={Link} last to={getLink(totalPages)} />
				</PaginationItem>
				: null}
		</Pagination>
	</div>;
}

function usePrevEffect(effect, params) {
	const previous = useRef(params);
	useEffect(() => {
		const out = effect(...previous.current);
		previous.current = params;
		return out;
	}, params);
}

function useFetch(effect) {
	const [fetch, setFetch] = useState(false);
	usePrevEffect((prevFetch) => {
		if(fetch) {
			setFetch(false);
		} else if(prevFetch) {
			return effect();
		}
	}, [fetch]);
	return () => {
		if(!fetch) {
			setFetch(true);
		}
	};
}

function getQueryLink(search, page) {
	const query = {};
	for(const key in search) {
		if(typeof search[key] === 'object' && 'value' in search[key]) {
			query[key] = search[key].value;
		} else {
			query[key] = search[key];
		}
	}
	query.page = page;
	return `?${queryString.stringify(query)}`;
}

function Paginator({ values: defaultValues, pages, post, autoSubmit, canSubmit, form, renderInputs, children, ...pass }) {
	delete pass.forceFormData;
	const { postData, getData } = useApi();
	const { search } = useLocationParams();
	const [values, setValues] = useState(defaultValues);
	const [getting, setGetting] = useState(null);
	const [render, setRender] = useState(null);
	useModifiable(applyModifications => {
		setRender(prev => {
			if(prev?.items?.length) {
				let changed = false;
				const items = prev.items.map(item => {
					const replacement = applyModifications(item);
					if(item !== replacement) {
						changed = true;
					}
					return replacement;
				});
				if(changed) {
					return { ...prev, items };
				}
			}
			return prev;
		});
	});
	const page = Math.max(+search.page || 1, 1);
	function fetchPage() {
		setGetting(page);
		const send = { page };
		if(values) {
			Object.assign(send, values);
		}
		let current = true;
		(post ? postData(pages, send) : getData(pages, send)).then(data => {
			if(current) {
				setRender(data);
			}
		}).finally(() => {
			if(current) {
				setGetting(null);
			}
		});
		return () => {
			current = false;
		};
	}
	const fetch = useFetch(fetchPage);
	useEffect(() => {
		let update = false;
		if(render !== null) {
			setRender(null);
			update = true;
		}
		if(getting !== null) {
			setGetting(null);
			update = true;
		}
		if(update) {
			fetch();
		}
	}, [postData, getData, pages]);
	useEffect(fetch, [values, page]);
	useReload(pass.id, fetch);
	const navigate = useNavigate();
	function fetchWithValues(v) {
		if(post) {
			if(page !== 1) {
				navigate(getQueryLink(search, 1));
			}
			setValues(v);
		} else {
			navigate(getQueryLink(v || {}, 1));
		}
	}
	usePrevEffect((prevValues) => {
		if(autoSubmit && prevValues !== defaultValues) {
			if(autoSubmit === true) {
				fetchWithValues(defaultValues);
			} else if(defaultValues) {
				const changed = {};
				autoSubmit.forEach(field => {
					if(!prevValues && field in defaultValues || prevValues && defaultValues[field] !== prevValues[field]) {
						changed[field] = defaultValues[field];
					}
				});
				if(Object.keys(changed).length) {
					fetchWithValues({ ...defaultValues, ...changed});
				}
			}
		}
	}, [defaultValues, autoSubmit]);
	useEffect(() => {
		if(search && defaultValues && !post) {
			let changed = false;
			const clone = Object.assign({}, values);
			Object.keys(defaultValues).forEach(key => {
				if(key in search) {
					const value = search[key];
					if(clone[key] !== value) {
						changed = true;
						clone[key] = value;
					}
				}
			});
			if(changed) {
				setValues(clone);
			}
		}
	}, [search, defaultValues]);
	if(!render) {
		return null;
	}
	return <PaginatorComponent render={render} pass={pass}
		getting={getting} form={form} values={defaultValues}
		canSubmit={canSubmit} renderInputs={renderInputs}
		fetchWithValues={fetchWithValues} getLink={p => getQueryLink(search, p)}>
		{children}
	</PaginatorComponent>;
}

export default withInputs(Paginator);
