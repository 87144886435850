import axios from 'axios';
import HttpStatus from 'http-status-codes';
import queryString from 'query-string';
const BASE = '/api';

const client = axios.create({
	baseURL: BASE,
	paramsSerializer: params => queryString.stringify(params)
});

function getLoginUrl() {
	return `${window.location.origin}${BASE}/auth/login?redirect=${encodeURIComponent(window.location)}`;
}

function forceLogin(data) {
	const { login: url } = data;
	window.location = `${url}?login=${encodeURIComponent(getLoginUrl())}`;
}

function checkLogin(err) {
	if (err.response && err.response.status === HttpStatus.UNAUTHORIZED && err.response.data && err.response.data.login) {
		forceLogin(err.response.data);
		err.notLoggedIn = true;
	}
	return Promise.reject(err);
}

const FUNCTIONS = {
	del(...params) {
		return client.delete(...params).catch(checkLogin);
	},
	get(...params) {
		return client.get(...params).catch(checkLogin);
	},
	patch(...params) {
		return client.patch(...params).catch(checkLogin);
	},
	post(...params) {
		return client.post(...params).catch(checkLogin);
	},
	put(...params) {
		return client.put(...params).catch(checkLogin);
	}
};
FUNCTIONS.delete = FUNCTIONS.del;
const LOGGED_OUT = { loggedIn: false, user: null };

export async function isLoggedIn() {
	const { ott } = queryString.parse(window.location.search);
	const headers = {};
	if(ott) {
		headers['PTMD-OTT'] = ott;
	}
	try {
		const { data } = await client.get('/auth/me', { headers });
		return { loggedIn: true, user: data };
	} catch(err) {
		if(err.response && err.response.status === HttpStatus.UNAUTHORIZED) {
			return LOGGED_OUT;
		}
		throw err;
	}
}

export async function login(force = false) {
	try {
		const { data } = await FUNCTIONS.get('/auth/me');
		if(force && !data.loggedIn) {
			forceLogin(data);
		}
		return { loggedIn: true, user: data };
	} catch(err) {
		if(!err.notLoggedIn) {
			throw err;
		}
		return LOGGED_OUT;
	}
}

export function logout() {
	return client.post('/auth/logout').then(() => LOGGED_OUT);
}

export function getHeaders() {
	return FUNCTIONS.get('/headers').then(({ data }) => data.headers);
}

export function getHomePage() {
	return FUNCTIONS.get('/home').then(({ data }) => data);
}

function page(api, endpoint, type = 'pages') {
	return `/${type}/${api}${endpoint.startsWith('/') ? '' : '/'}${endpoint}`;
}

export function getRawUrl(api, endpoint) {
	return BASE + page(api, endpoint, 'raw');
}

export async function getPage(api, endpoint) {
	const { data } = await FUNCTIONS.get(page(api, endpoint));
	if (typeof data.page !== 'object') {
		const err = new Error('Not a page');
		err.response = { status: HttpStatus.NOT_FOUND };
		throw err;
	}
	return data.page;
}

export function callMethod(method, endpoint, api, data = {}) {
	return FUNCTIONS[method](page(api, endpoint), data).then(r => r.data);
}

function isFileData(data) {
	return data instanceof FileList;
}

function stringify(object, data, addFile) {
	let file = 0;
	let prefix = '_genfile';
	while(prefix in data) {
		prefix += '.';
	}
	return JSON.stringify(object, (key, value) => {
		if(isFileData(value)) {
			file++;
			const filename = prefix + file;
			addFile(filename, value);
			return { filename, single: value.length === 1 };
		}
		return value;
	});
}

function createInput(name, type = 'hidden') {
	const input = document.createElement('input');
	input.hidden = true;
	input.type = type;
	input.name = name;
	return input;
}

export function callRawMethod(method, endpoint, api, data) {
	const form = document.createElement('form');
	form.method = method;
	form.action = getRawUrl(api, endpoint);
	document.body.appendChild(form);
	let hasFiles = false;
	if(data) {
		const addFile = (name, files) => {
			const input = createInput(name, 'file');
			input.files = files;
			form.appendChild(input);
			hasFiles = true;
		};
		Object.keys(data).forEach(key => {
			const input = createInput(key);
			const value = data[key];
			if(isFileData(value)) {
				input.type = 'file';
				input.files = value;
				hasFiles = true;
			} else {
				input.value = stringify(data[key], data, addFile);
			}
			form.appendChild(input);
		});
	}
	if(hasFiles) {
		form.enctype = 'multipart/form-data';
	}
	form.submit();
	document.body.removeChild(form);
}

export function submitForm(api, endpoint, data = {}, useFormData = false) {
	const formData = new FormData();
	const addFile = (name, files) => {
		useFormData = true;
		for(let i = 0; i < files.length; i++) {
			formData.append(name, files[i], files[i].name);
		}
	};
	for(const key in data) {
		const value = data[key];
		if(isFileData(value)) {
			addFile(key, value);
		} else {
			formData.append(key, stringify(value, data, addFile));
		}
	}
	return callMethod('post', endpoint, api, useFormData ? formData : data);
}

export function getData(api, endpoint, params) {
	return FUNCTIONS.get(page(api, endpoint), { params });
}

export function postData(api, endpoint, params) {
	return FUNCTIONS.post(page(api, endpoint), params);
}
