import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { TitleContainer } from './components/Title';

import './css/index.scss';

document.getElementById('unsupported-browser').remove();

ReactDOM.render(<TitleContainer>
	<App />
</TitleContainer>, document.getElementById('root'));
