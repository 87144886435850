import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { checkBoxStyle, formControlLabelStyle } from '../../../css/Styles';

const StyledCheckbox = withStyles(checkBoxStyle)(Checkbox);
const StyledLabel = withStyles(formControlLabelStyle)(FormControlLabel);

export default props => {
	return <StyledLabel control={<StyledCheckbox {...props} />} />;
};
