import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './portal/Header';
import Menu from './portal/Menu';
import WelcomePage from './portal/WelcomePage';
import Page from './portal/Page';
import PageState from './PageState';
import { getHeaders } from '../api';
import { useLogout } from './UserState';
import { useError } from './ErrorBoundary';

function useHeaders() {
	const setError = useError();
	const [headers, setHeaders] = useState([]);
	const updateHeaders = () => getHeaders().then(setHeaders).catch(setError);
	useEffect(() => {
		updateHeaders();
		return () => setHeaders([]);
	}, []);
	return { headers, updateHeaders };
}

export default function Portal() {
	const logout = useLogout();
	const { headers, updateHeaders } = useHeaders();
	return <BrowserRouter>
		<PageState>
			<Header headers={headers} id="header" updateHeaders={updateHeaders} logout={logout} />
			<div id="portal-body">
				<Menu headers={headers} />
				<div id="portal-pages">
					<Routes>
						<Route path="/portal/:api/:endpoint/*" element={<Page />} />
						<Route path="/" element={<WelcomePage />} />
					</Routes>
				</div>
			</div>
		</PageState>
	</BrowserRouter>;
}
