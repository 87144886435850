import React from 'react';
import HttpStatus from 'http-status-codes';
import { Alert } from 'reactstrap';

export default function ErrorPage({ error }) {
	let title = 'Unknown error';
	let message = 'An error has occurred.';
	if(error.response) {
		const { status, data } = error.response;
		if(status === HttpStatus.NOT_FOUND) {
			title = 'Not found';
			message = 'The requested page could not be found.';
		} else if(status === HttpStatus.FORBIDDEN) {
			title = 'Forbidden';
			message = 'You are not allowed to access this page.';
		} else if(status === HttpStatus.BAD_REQUEST && data && data.invalid) {
			title = 'Invalid input';
			message = `Invalid fields: ${data.invalid.join(', ')}.`;
		} else if(status === HttpStatus.REQUEST_TOO_LONG) {
			title = 'Invalid input';
			message = 'The file you tried to upload was too large.';
		}
	}
	return <main className="bg-white container p-4 mt-5">
		<Alert color="danger" className="mb-0">
			<h1>{title}</h1>
			<p className="mb-0">{message}</p>
		</Alert>
	</main>;
}
