import moment from 'moment';

export default function Time(props) {
	const value = new Date(props.value);
	if(props.format) {
		return moment(value).format(props.format);
	} else if(props.time && props.date) {
		return value.toLocaleString();
	} else if(props.time) {
		return value.toLocaleTimeString();
	} else if(props.date) {
		return value.toLocaleDateString();
	} else if(!props.format && !props.time && !props.date) {
		return value.toLocaleString();
	}
	return null;
}
