import React from 'react';
import { Alert } from 'reactstrap';

export default function ErrorAlert({ error }) {
	const message = error.response?.data?.message || error.message;
	return <Alert color="danger">
		<h4 className="alert-heading">{error.heading || 'Unknown error'}</h4>
		<p className="mb-0">{message}</p>
	</Alert>;
}
