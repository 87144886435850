import React from 'react';
import { Alert } from 'reactstrap';

export default function PortalAlert({ title, message, children, ...props }) {
	return <Alert {...props}>
		<h4 className="alert-heading">{title}</h4>
		<p className="mb-0">{message}</p>
		{children}
	</Alert>;
}
