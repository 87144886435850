import React, { useContext } from 'react';
import ErrorAlert from './ErrorAlert';

const ErrorContext = React.createContext(null);

export function useError() {
	return useContext(ErrorContext);
}

function ErrorPage({ error }) {
	return <div className="container bg-white p-5">
		<ErrorAlert error={error} />
	</div>;
}

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.setError = this.setError.bind(this);
	}

	static getDerivedStateFromError(error) {
		return { error };
	}

	setError(error) {
		this.setState({ error });
		if(error) {
			console.error(error);
		}
	}

	render() {
		const { children, Elem = ErrorPage } = this.props;
		const error = this.state?.error;
		if(error) {
			return <Elem error={error} />;
		}
		return <ErrorContext.Provider value={this.setError} children={children} />;
	}
}

export function withErrorBoundary(Child, Elem) {
	return props => <ErrorBoundary Elem={Elem}><Child {...props} /></ErrorBoundary>;
}
