import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import { useApi } from './ApiState';

function AutoComplete({ creatable, options, autoComplete, ...childProps }) {
	const { postData } = useApi();
	const Elem = creatable ? AsyncCreatable : AsyncSelect;
	return <Elem {...childProps} defaultOptions={options} cacheOptions loadOptions={value => postData(autoComplete, { value })} />;
}

export default function SelectWrapper({ multiple, searchable, creatable, autoComplete, onChange, values, className = 'select', ...childProps }) {
	childProps.isMulti = multiple || false;
	if(onChange) {
		childProps.onChange = option => {
			if(!option && childProps.isMulti) {
				option = [];
			}
			return onChange({ target: { value: option } });
		};
	}
	if(values !== undefined) {
		childProps.options = values;
		if(typeof childProps.value === 'string' && Array.isArray(values)) {
			const option = values.find(o => o.value === childProps.value);
			if(option) {
				childProps.value = option;
			}
		}
	}
	childProps.className = className;
	if(!creatable && !autoComplete) {
		childProps.isSearchable = searchable || false;
	}
	childProps.classNamePrefix = 'qx-select';
	if(autoComplete) {
		return <AutoComplete {...childProps} creatable={creatable} autoComplete={autoComplete} />;
	} else if(creatable) {
		return <Creatable {...childProps} />;
	}
	return <Select {...childProps} />;
}
