import React, { useContext, useEffect, useState } from 'react';

const DEFAULT_STATE = {
	api: null,
	page: null,
	reload: [],
	values: [],
	modify: [],
	actions: {}
};

function applyModification(action, root) {
	const { key, properties, element, children } = action;
	const parents = [];
	const search = [root];
	while(search.length) {
		const current = search.pop();
		if(parents.length && current === parents[parents.length - 1]) {
			parents.pop();
			continue;
		}
		if(current.properties?.key === key) {
			let c;
			let page;
			parents.push(current);
			parents.forEach(parent => {
				const child = Object.assign({}, parent);
				if(c) {
					const i = c.children.findIndex(e => e === parent);
					c.children = c.children.slice();
					c.children[i] = child;
				} else {
					page = child;
				}
				c = child;
			});
			if(properties) {
				c.properties = properties;
			}
			if(element) {
				c.element = element;
			}
			if(children) {
				c.children = children;
			}
			return page;
		} else if(current.children?.length) {
			parents.push(current);
			search.push(current, ...current.children);
		}
	}
}

function getActions(setState) {
	return () => {
		let calls = 0;
		const out = {
			getSetFuturePage() {
				const guard = calls;
				return (page, api) => {
					return out.setPage(page, api, guard);
				};
			},
			setPage(page, api, callsGuard) {
				if(callsGuard === undefined || callsGuard === calls) {
					setState(prev => ({ ...prev, page, api }));
				}
				return ++calls;
			},
			modifyPage(key, properties, element, children) {
				if(!key || !properties && !element && !children) {
					return;
				}
				setState(prev => {
					if(!prev.page) {
						return prev;
					}
					const action = { key, properties, element, children };
					const page = applyModification(action, prev.page);
					if(page) {
						return { ...prev, page };
					}
					return { ...prev, modify: prev.modify.concat(action) };
				});
			},
			reload(keys) {
				setState(prev => ({ ...prev, reload: prev.reload.concat(keys) }));
			},
			clearReload(key) {
				setState(prev => {
					const reload = prev.reload.filter(k => key !== k);
					if(prev.reload.length !== reload.length) {
						return { ...prev, reload };
					}
					return prev;
				});
			},
			setFormValue(form, vals) {
				setState(prev => {
					const i = prev.values.findIndex(f => f.form === form);
					const values = prev.values.slice();
					if(i >= 0) {
						values[i] = { ...values[i], values: { ...values[i].values, ...vals } };
					} else {
						values.push({ form, values: vals });
					}
					return { ...prev, values };
				});
			},
			clearFormValue(form) {
				setState(prev => {
					const values = prev.values.filter(v => v.form !== form);
					if(prev.values.length !== values.length) {
						return { ...prev, values };
					}
					return prev;
				});
			},
			clearModify(action) {
				setState(prev => {
					const modify = prev.modify.filter(m => m !== action);
					if(prev.modify.length !== modify.length) {
						return { ...prev, modify };
					}
					return prev;
				});
			}
		};
		return out;
	};
}

const PageContext = React.createContext(null);

export default function PageState({ children }) {
	const [state, setState] = useState(DEFAULT_STATE);
	[state.actions] = useState(getActions(setState));
	return <PageContext.Provider value={state} children={children} />;
}

export function usePageState() {
	return useContext(PageContext);
}

export function useReload(id, onReload) {
	const { reload, actions } = usePageState();
	const shouldReload = id && reload.includes(id);
	useEffect(() => {
		if(shouldReload) {
			onReload();
			actions.clearReload(id);
		}
	}, [id, onReload, shouldReload]);
}

export function useModifiable(onChange) {
	const { modify, actions } = usePageState();
	useEffect(() => {
		if(modify.length) {
			const apply = root => {
				for(const action of modify) {
					const replacement = applyModification(action, root);
					if(replacement) {
						actions.clearModify(action);
						return replacement;
					}
				}
				return root;
			};
			onChange(apply);
		}
	}, [modify]);
}
