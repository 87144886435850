import React, { useContext, useEffect, useState } from 'react';
import { useError } from './ErrorBoundary';
import { isLoggedIn, login, logout } from '../api';

const UserContext = React.createContext(null);
const EMPTY_STATE = {
	loggedIn: null,
	user: null
};

export default function UserState({ children }) {
	const setError = useError();
	const userState = useState(EMPTY_STATE);
	useEffect(() => {
		isLoggedIn().then(userState[1]).catch(setError);
	}, []);
	return <UserContext.Provider value={userState} children={children} />;
}

export function useUser() {
	return useContext(UserContext)[0];
}

export function useLogin(setError = useError()) {
	const setUser = useContext(UserContext)[1];
	return force => login(force).then(setUser).catch(setError);
}

export function useLogout() {
	const setUser = useContext(UserContext)[1];
	const setError = useError();
	return () => logout().then(setUser).catch(setError);
}

export function useLocale() {
	const { user } = useUser();
	return user?.locale || window.navigator.language;
}
