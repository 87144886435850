import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default function PortalModal({ modalProps, headerProps, footerProps, bodyProps, children, button = 'Open', header, ...props }) {
	const [open, setOpen] = useState(false);
	const toggle = e => {
		e?.preventDefault?.();
		setOpen(!open);
	};
	return <>
		<Button {...props} onClick={toggle}>{button}</Button>
		<Modal {...modalProps} isOpen={open} toggle={toggle} children={children}>
			<ModalHeader {...headerProps} toggle={toggle}>{header}</ModalHeader>
			<ModalBody {...bodyProps} children={children} />
			<ModalFooter {...footerProps}>
				<Button onClick={toggle}>Close</Button>
			</ModalFooter>
		</Modal>
	</>;
}
