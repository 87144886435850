import React, { useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useApi } from './ApiState';

const DELAY = 500;
const THEME = {
	container: 'dropdown',
	containerOpen: '',
	input: 'form-control',
	inputFocused: '',
	suggestionsContainer: 'dropdown-menu',
	suggestionsContainerOpen: 'show shadow-sm',
	suggestionsList: 'list-group',
	suggestion: 'dropdown-item',
	suggestionFirst: '',
	suggestionHighlighted: 'active',
	sectionContainer: '',
	sectionContainerFirst: '',
	sectionTitle: ''
};

function getValue(suggestion) {
	return suggestion.value;
}

function renderSuggestion(suggestion) {
	return <span>{suggestion.label || suggestion.value}</span>;
}

export default function AutoComplete({ autoComplete, onChange, ...props }) {
	const { postData } = useApi();
	const [suggestions, setSuggestions] = useState([]);
	const timeout = useRef(0);
	props.onChange = (e, { newValue }) => onChange({ target: { value: newValue } });
	return <Autosuggest
		id={`autocomplete_${props.id || props.name || ''}`}
		theme={THEME}
		suggestions={suggestions}
		onSuggestionsFetchRequested={({ value }) => {
			clearTimeout(timeout.current);
			timeout.current = setTimeout(() => {
				postData(autoComplete, { value }).then(setSuggestions);
			}, DELAY);
		}}
		onSuggestionsClearRequested={() => setSuggestions([])}
		onSuggestionSelected={e => e.preventDefault()}
		getSuggestionValue={getValue}
		renderSuggestion={renderSuggestion}
		inputProps={props} />;
}
