import React from 'react';
import { Link } from 'react-router-dom';
import { useApi } from './ApiState';
import { getRawUrl } from '../../../api';

export default function PortalLink({ to, raw, context, ...props }) {
	const { api } = useApi();
	if(!to) {
		return null;
	}
	if(raw) {
		return <a {...props} href={getRawUrl(context || api, to)} />;
	}
	return <Link {...props} to={`/portal/${context || api}${to.startsWith('/') ? '' : '/'}${to}`} />;
}
