import React from 'react';
import { useApi } from './ApiState';
import { getRawUrl } from '../../../api';

export default function PortalImage({ src, context, ...props }) {
	const { api } = useApi();
	if(!src) {
		return null;
	}
	return <img {...props} src={getRawUrl(context || api, src)} />;
}
