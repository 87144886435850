const subColor = 'rgb(232, 197, 106)';

const checkBoxStyle = {
	root: {
		color: subColor,
		'&$checked': {
			color: subColor
		},
		padding: 0,
		fontSize: '1em',
		'& .MuiSvgIcon-root': {
			fontSize: '1.5em'
		}
	},
	checked: {}
};

const formControlLabelStyle = {
	root: {
		display: 'inline-flex',
		alignItems: 'center',
		marginLeft: '0',
		marginRight: '0',
		verticalAlign: 'bottom'
	}
};

export {
	subColor,
	checkBoxStyle,
	formControlLabelStyle
};
