import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFormContext } from '../../../hoc/form';

export const MultiValue = React.createContext(null);

function getChangeObjectValue(changeValue, name) {
	return key => {
		const onChange = changeValue(name);
		return e => {
			onChange((prevValues = {}) => {
				let replacement;
				if(typeof e === 'function') {
					replacement = e(prevValues?.[key]);
				} else {
					replacement = e.target.value;
				}
				if(prevValues?.[key] !== replacement) {
					return { ...prevValues, [key]: replacement };
				}
				return prevValues;
			});
		};
	};
}

function useObjectContext(changeContext, values, name) {
	const [changeValue, setChangeValue] = useState(() => getChangeObjectValue(changeContext, name));
	useEffect(() => {
		setChangeValue(() => getChangeObjectValue(changeContext, name));
	}, [changeContext, name]);
	return useMemo(() => {
		return { values, changeValue };
	}, [changeValue, values]);
}

function FormValueGroup({ context, value, name, children }) {
	const objectContext = useObjectContext(context?.changeValue, context?.values?.[name] || null, name);
	useEffect(() => {
		if(context && value) {
			const onChange = context.changeValue(name);
			onChange({ target: { value } });
		}
	}, [value]);
	if(context && value && !context.values) {
		return null;
	}
	return <FormProvider context={objectContext} children={children} />;
}

function hasValue(value) {
	if(typeof value !== 'object') {
		return value;
	} else if(Array.isArray(value)) {
		return value.some(hasValue);
	}
	for(const key in value) {
		if(hasValue(value[key])) {
			return true;
		}
	}
	return false;
}

function getChangeArrayValue(changeValue, name) {
	return i => {
		const onChange = changeValue(name);
		return e => {
			onChange((prevValues = []) => {
				let replacement;
				if(typeof e === 'function') {
					replacement = e(prevValues?.[i]);
				} else {
					replacement = e.target.value;
				}
				if(prevValues?.[i] !== replacement) {
					const copy = prevValues?.slice() || [];
					copy[i] = replacement;
					return copy;
				}
				return prevValues;
			});
		};
	};
}

function useArrayContext(changeContext, values, name) {
	const [changeValue, setChangeValue] = useState(() => getChangeArrayValue(changeContext, name));
	useEffect(() => {
		setChangeValue(() => getChangeArrayValue(changeContext, name));
	}, [changeContext, name]);
	return useMemo(() => {
		return { values, changeValue };
	}, [changeValue, values]);
}

function MultiFormValueGroup({ context, name, value, ...props }) {
	useEffect(() => {
		if(context && value) {
			const onChange = context.changeValue(name);
			onChange({ target: { value } });
		}
	}, [value, name]);
	const values = context?.values?.[name] || [];
	const arrayContext = useArrayContext(context?.changeValue, values, name);
	if(context && value && !context.values) {
		return null;
	}
	const deleteValue = i => {
		const onChange = context.changeValue(name);
		return e => {
			e.preventDefault();
			onChange(prevValues => {
				if(i < prevValues?.length) {
					const copy = prevValues?.slice() || [];
					copy.splice(i, 1);
					return copy;
				}
				return prevValues;
			});
		};
	};
	const renderInput = i => {
		return <MultiValue.Provider key={i} value={i === values.length ? null : deleteValue(i)}>
			<FormValueGroup {...props} name={i} context={arrayContext} />
		</MultiValue.Provider>;
	};
	let add = true;
	return <>
		{values.map((v, i) => {
			if(!hasValue(v)) {
				add = false;
			}
			return renderInput(i);
		})}
		{add && renderInput(values.length)}
	</>;
}

export default function FormValueGroupWrapper({ multiple, ...props }) {
	const context = useFormContext();
	if(!props.name) {
		return null;
	}
	if(multiple) {
		return <MultiFormValueGroup {...props} context={context} />;
	}
	return <FormValueGroup {...props} context={context} />;
}
