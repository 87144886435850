import React, { useMemo } from 'react';
import { Input } from 'reactstrap';

const HEADER = 4;
const A = 'A'.charCodeAt(0);
const LETTER_START = 10;
const MAX_CHUNK = 9;
const MOD = 97;

function invalidIBAN(iban) {
	if(iban) {
		if(!/^[A-Z0-9\s]{5,}$/.test(iban)) {
			return true;
		}
		iban = iban.replace(/\s+/g, '');
		iban = iban.slice(HEADER) + iban.slice(0, HEADER);
		let chunk = '';
		for(let i = 0; i < iban.length; i++) {
			let c = iban[i];
			if(c >= 'A' && c <= 'Z') {
				c = `${c.charCodeAt(0) - A + LETTER_START}`;
			}
			chunk += c;
			if(chunk.length >= MAX_CHUNK) {
				const modulo = +chunk.slice(0, MAX_CHUNK) % MOD;
				chunk = `${modulo}${chunk.slice(MAX_CHUNK)}`;
			}
		}
		if(+chunk % MOD !== 1) {
			return true;
		}
	}
}

export default function IBANInput({ onChange, value, ...props }) {
	const invalid = useMemo(() => invalidIBAN(value), [value]);
	return <Input {...props} type="text" pattern="[A-Z0-9\s]{5,}" value={value} invalid={invalid} onChange={e => {
		e.target.value = e.target.value.toUpperCase();
		onChange(e);
	}} />;
}
