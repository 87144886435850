import React from 'react';
import LoginPage from './LoginPage';
import Portal from './Portal';
import UserState, { useUser } from './UserState';
import { withErrorBoundary } from './ErrorBoundary';

function App() {
	const { loggedIn } = useUser();
	if(loggedIn === false) {
		return <LoginPage />;
	} else if(!loggedIn) {
		return null;
	}
	return <Portal />;
}

export default withErrorBoundary(() => <UserState><App /></UserState>);
