export default class Cache {
	constructor(supplier) {
		this.supplier = supplier;
		this.values = {};
	}

	get(key) {
		if(!Array.isArray(key)) {
			key = [key];
		}
		let level = this.values;
		const last = key.length - 1;
		for(let i = 0; i < last; i++) {
			if(!level[key[i]]) {
				level[key[i]] = {};
			}
			level = level[key[i]];
		}
		if(!(key[last] in level)) {
			key[last] = this.supplier(key);
		}
		return key[last];
	}
}
