import React from 'react';
import { NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

function ItemLink({ path, raw, ...pass }) {
	delete pass.category;
	if(raw) {
		return <a {...pass} href={path} />;
	}
	if(path) {
		pass.to = `/portal${path}`;
	} else {
		pass.to = '/';
	}
	return <Link {...pass} />;
}

function Item({ type: Type, name, icon, ...pass }) {
	return <Type {...pass}>
		{icon && <><img src={`/icons/${icon}.png`} className="brand-icon" alt="" /> </>}
		{name}
	</Type>;
}

export default function PortalNav(props) {
	const { name, paths, ...pass } = props;
	if(paths) {
		return <UncontrolledDropdown nav>
			<DropdownToggle nav caret {...pass}>{name}</DropdownToggle>
			<DropdownMenu right>
				{paths.map((item, i) => <Item {...item} tag={ItemLink} key={i} type={DropdownItem} className={'dropdown-item' + (item.className ? ` ${item.className}` : '')} />)}
			</DropdownMenu>
		</UncontrolledDropdown>;
	}
	return <NavItem>
		<Item {...props} type={ItemLink} className={'nav-link' + (props.className ? ` ${props.className}` : '')} />
	</NavItem>;
}
