import React, { useContext, useEffect, useState } from 'react';

const TitleContext = React.createContext(null);

function getContext(setState) {
	return {
		set(id, title) {
			setState(state => {
				const index = state.stack.findIndex(element => element.id === id);
				const stack = state.stack.slice();
				if(index >= 0) {
					stack[index] = { id, title };
				} else {
					stack.push({ id, title });
				}
				return { title: stack[stack.length - 1].title, stack };
			});
		},
		unset(id) {
			setState(state => {
				const stack = state.stack.filter(element => element.id !== id);
				return { title: stack[stack.length - 1].title, stack };
			});
		}
	};
}

function getInitalState() {
	const { title } = document;
	return { title, stack: [ { id: Symbol('base'), title } ] };
}

export function TitleContainer({ children }) {
	const [state, setState] = useState(getInitalState);
	const [context] = useState(getContext(setState));
	const { title } = state;
	useEffect(() => {
		document.title = title;
	}, [title]);
	return <TitleContext.Provider value={context} children={children} />;
}

export default function Title({ title, children }) {
	const [id] = useState(() => Symbol());
	const { set, unset } = useContext(TitleContext);
	useEffect(() => {
		if(title) {
			set(id, title);
		}
		return () => unset(id);
	}, [title]);
	return children ?? null;
}
