import React from 'react';
import { Input, InputGroup } from 'reactstrap';

export default function RangeInput({ value, onChange, min, max, ...props }) {
	const minProps = { ...min, value: value?.min || '' };
	const maxProps = { ...max, value: value?.max || '' };
	if(onChange) {
		const change = key => e => {
			onChange({ target: { value: { ...value, [key]: e.target.value } } });
		};
		minProps.onChange = change('min');
		maxProps.onChange = change('max');
	}
	return <InputGroup {...props}>
		<Input {...minProps} />
		<Input {...maxProps} />
	</InputGroup>;
}
