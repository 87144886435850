import { useLocale } from '../../UserState';
import Cache from '../../../cache';

const cache = new Cache(([locale, currency]) => {
	if(!window.Intl || !Intl.NumberFormat) {
		return {
			format(number) {
				return `${number} ${currency}`;
			}
		};
	}
	return new Intl.NumberFormat(locale, { style: 'currency', currency });
});

export default function Currency({ currency, number = null }) {
	const locale = useLocale();
	if(isNaN(number) || !currency) {
		if(number === 'NaN') {
			return '---';
		}
		return number;
	}
	return cache.get([locale, currency]).format(number);
}
