import React from 'react';
import Title from '../../Title';
import { Container } from '@material-ui/core';

export default function StandardPage({ title, wrapperTheme, enableGutters, ...props }) {
	props.className = `${props.className || ''} pt-5 pb-2 px-5 container-fluid`;
	return <Title title={title}>
		<div className={`page-container-wrapper ${wrapperTheme || 'bg-white'}`}>
			{enableGutters ? <Container {...props} /> : <div {...props} />}
		</div>
	</Title>;
}
