import React, { useState } from 'react';
import { useApi } from './ApiState';
import { fire } from '../../../prompt';

const METHODS = ['post', 'get', 'delete', 'put', 'print', 'copy'];

export default function ActionButton(originalProps) {
	const { callMethod, callRawMethod } = useApi();
	const [submitting, setSubmitting] = useState(false);
	const { prompt, raw, allowMultipleSubmit, context, ...props } = originalProps;
	if(!METHODS.reduce((res, method) => {
		delete props[method];
		return res || method in originalProps;
	}, false)) {
		return null;
	}
	if(!allowMultipleSubmit && submitting) {
		props.disabled = true;
	}
	const submit = () => {
		setSubmitting(true);
		const func = raw ? callRawMethod : callMethod;
		Promise.all(METHODS.map(method => {
			if(method in originalProps) {
				if(method === 'print') {
					if(originalProps.print) {
						window.print();
					}
				} else if(method === 'copy') {
					if(navigator.clipboard && navigator.clipboard.writeText) {
						return navigator.clipboard.writeText(originalProps[method]);
					}
					console.warn('navigator.clipboard not supported');
				} else {
					return func(method, originalProps[method], context);
				}
			}
		}).filter(Boolean)).finally(() => {
			setSubmitting(false);
		});
	};
	return <button {...props} onClick={e => {
		e.preventDefault();
		if(prompt) {
			return fire(prompt).then(res => {
				if(res.value) {
					submit();
				}
			});
		}
		submit();
	}} />;
}
